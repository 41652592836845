"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./error.style.scss.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/forms");
var i3 = require("./error.component");
var i4 = require("@angular/router");
var styles_ErrorComponent = [i0.styles];
var RenderType_ErrorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ErrorComponent, data: {} });
exports.RenderType_ErrorComponent = RenderType_ErrorComponent;
function View_ErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "main", [["class", "error-container"], ["id", "content"], ["role", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 17, "div", [["class", "col-xl-4 col-md-6 col-xs-10 offset-xl-4 offset-md-3 offset-xs-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 16, "div", [["class", "error-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["class", "error-code"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "error-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Opps, it seems that this page does not exist. "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "error-help mb"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" If you are sure it should, search for it. "])), (_l()(), i1.ɵeld(11, 0, null, null, 9, "form", [["method", "get"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.searchResult() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(13, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(15, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "input", [["class", "form-control input-no-border"], ["placeholder", "Search Pages"], ["type", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["class", "btn btn-inverse"], ["type", "submit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Search "])), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "fa fa-search text-warning ml-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "footer", [["class", "page-footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 2016 \u00A9 Sing. Admin Dashboard Template. "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 15).ngClassValid; var currVal_5 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 11, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
exports.View_ErrorComponent_0 = View_ErrorComponent_0;
function View_ErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "error", [["class", "error-page app"]], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i1.ɵdid(1, 49152, null, 0, i3.ErrorComponent, [i4.Router], null, null)], null, null); }
exports.View_ErrorComponent_Host_0 = View_ErrorComponent_Host_0;
var ErrorComponentNgFactory = i1.ɵccf("error", i3.ErrorComponent, View_ErrorComponent_Host_0, {}, {}, []);
exports.ErrorComponentNgFactory = ErrorComponentNgFactory;
