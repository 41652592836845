"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Role = /** @class */ (function () {
    function Role() {
    }
    return Role;
}());
exports.Role = Role;
var RolePermission = /** @class */ (function () {
    function RolePermission() {
        this.canCreate = false;
        this.canEdit = false;
        this.canView = false;
        this.canDelete = false;
        this.canPrint = false;
        this.canGenerate = false;
        this.quotationReview = false;
        this.quotationApproval = false;
        this.overduePayment = false;
        this.overdueWindow = false;
        this.allProjectInProgress = false;
        this.projectNotAllocated = false;
        this.userProjectInProgress = false;
    }
    return RolePermission;
}());
exports.RolePermission = RolePermission;
var Permission = /** @class */ (function () {
    function Permission() {
    }
    return Permission;
}());
exports.Permission = Permission;
var Permissions;
(function (Permissions) {
    Permissions[Permissions["Inquiry"] = 1] = "Inquiry";
    Permissions[Permissions["Freight"] = 2] = "Freight";
    Permissions[Permissions["Quotation"] = 3] = "Quotation";
    Permissions[Permissions["RequestReview"] = 4] = "RequestReview";
    Permissions[Permissions["ReviewResult"] = 5] = "ReviewResult";
    Permissions[Permissions["RequestApproval"] = 6] = "RequestApproval";
    Permissions[Permissions["ApprovalResult"] = 7] = "ApprovalResult";
    Permissions[Permissions["Contract"] = 8] = "Contract";
    Permissions[Permissions["FleetSchedule"] = 9] = "FleetSchedule";
    Permissions[Permissions["RequestEngineeringSupport"] = 10] = "RequestEngineeringSupport";
    Permissions[Permissions["FeedbackEngineeringSupport"] = 11] = "FeedbackEngineeringSupport";
    Permissions[Permissions["Client"] = 12] = "Client";
    Permissions[Permissions["Report"] = 13] = "Report";
    Permissions[Permissions["SystemConfiguration"] = 14] = "SystemConfiguration";
    Permissions[Permissions["Users"] = 15] = "Users";
    Permissions[Permissions["Roles"] = 16] = "Roles";
    Permissions[Permissions["Dashboard"] = 17] = "Dashboard";
    Permissions[Permissions["ScheduleSimulation"] = 18] = "ScheduleSimulation";
    Permissions[Permissions["InquiryAndFreightList"] = 19] = "InquiryAndFreightList";
    Permissions[Permissions["VoyageCostAndIncome"] = 20] = "VoyageCostAndIncome";
    Permissions[Permissions["VoyageBunkerCalc"] = 21] = "VoyageBunkerCalc";
    Permissions[Permissions["VoyageInvoice"] = 22] = "VoyageInvoice";
})(Permissions = exports.Permissions || (exports.Permissions = {}));
