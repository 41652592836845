﻿import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Role, RolePermission, Permission } from '../roles/dtos/role.viewmodel';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class LoginService {
    private _login = '/api/auth/login';
    private _loginWithMicrosoft = '/api/auth/loginWithMicrosoft';
    private _logout = '/api/auth/logout';
    private _currentUser = '/api/auth/currentUser';
    private _checkSession = '/api/auth/checksession';
    private _getPermissions = '/api/auth/getPermissions';
    private _requestPasswordRecovery = '/api/auth/requestPasswordRecovery';
    private _resetPassword = '/api/auth/resetPassword';

    constructor(private http: Http) { }

    requestPasswordRecovery(username: string) {
        return this.http.get(this._requestPasswordRecovery + "?username=" + username)
            .catch(this.handleError);
    }

    resetPassword(userId: string, token: string) {
        return this.http.get(this._requestPasswordRecovery + "?userId=" + userId + "&token=" + token)
            .catch(this.handleError);
    }

    login(username: string, password: string, rememberMe: boolean): Observable<any> {
        return this.http.get(this._login + "?username=" + username + "&password=" + password + "&remember=" + rememberMe)
            .catch(this.handleError);
    }

    loginWithMicrosoft(token: string, rememberMe: boolean): Observable<string> {
        return this.http.post(this._loginWithMicrosoft, { idToken: token, remember: rememberMe })
            .map(res => res.json().data)
            .catch(this.handleError);
    }

    logout(): Observable<any> {
        return this.http.get(this._logout);
    }

    getCurrentUserIds(): Observable<any> {
        return this.http.get(this._currentUser)
            .map(res => res.json().data);
    }

    checkSession(): Observable<boolean> {
        return this.http.get(this._checkSession)
            .map(res => <boolean>res.json().data);
    }

    getPermissions(): Observable<RolePermission[]> {
        return this.http.get(this._getPermissions)
            .map(res => <RolePermission[]>res.json().data)
            .catch(this.handleError);
    }

    public handleError: () => Observable<any> = this.handleErrorUnbinded.bind(this);

    public handleErrorUnbinded(error: Response) {
        return Observable.throw(error.json().error || 'Server error');
    }
}