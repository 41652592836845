"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/http");
var role_viewmodel_1 = require("../roles/dtos/role.viewmodel");
require("rxjs/add/operator/map");
require("rxjs/add/operator/toPromise");
require("rxjs/Rx");
var Observable_1 = require("rxjs/Observable");
var LoginService = /** @class */ (function () {
    function LoginService(http) {
        this.http = http;
        this._login = '/api/auth/login';
        this._loginWithMicrosoft = '/api/auth/loginWithMicrosoft';
        this._logout = '/api/auth/logout';
        this._currentUser = '/api/auth/currentUser';
        this._checkSession = '/api/auth/checksession';
        this._getPermissions = '/api/auth/getPermissions';
        this._requestPasswordRecovery = '/api/auth/requestPasswordRecovery';
        this._resetPassword = '/api/auth/resetPassword';
        this.handleError = this.handleErrorUnbinded.bind(this);
    }
    LoginService.prototype.requestPasswordRecovery = function (username) {
        return this.http.get(this._requestPasswordRecovery + "?username=" + username)
            .catch(this.handleError);
    };
    LoginService.prototype.resetPassword = function (userId, token) {
        return this.http.get(this._requestPasswordRecovery + "?userId=" + userId + "&token=" + token)
            .catch(this.handleError);
    };
    LoginService.prototype.login = function (username, password, rememberMe) {
        return this.http.get(this._login + "?username=" + username + "&password=" + password + "&remember=" + rememberMe)
            .catch(this.handleError);
    };
    LoginService.prototype.loginWithMicrosoft = function (token, rememberMe) {
        return this.http.post(this._loginWithMicrosoft, { idToken: token, remember: rememberMe })
            .map(function (res) { return res.json().data; })
            .catch(this.handleError);
    };
    LoginService.prototype.logout = function () {
        return this.http.get(this._logout);
    };
    LoginService.prototype.getCurrentUserIds = function () {
        return this.http.get(this._currentUser)
            .map(function (res) { return res.json().data; });
    };
    LoginService.prototype.checkSession = function () {
        return this.http.get(this._checkSession)
            .map(function (res) { return res.json().data; });
    };
    LoginService.prototype.getPermissions = function () {
        return this.http.get(this._getPermissions)
            .map(function (res) { return res.json().data; })
            .catch(this.handleError);
    };
    LoginService.prototype.handleErrorUnbinded = function (error) {
        return Observable_1.Observable.throw(error.json().error || 'Server error');
    };
    return LoginService;
}());
exports.LoginService = LoginService;
