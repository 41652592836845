"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("../../node_modules/messenger/build/css/messenger.css.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../node_modules/ng2-slim-loading-bar/src/slim-loading-bar.component.ngfactory");
var i3 = require("ng2-slim-loading-bar/src/slim-loading-bar.component");
var i4 = require("ng2-slim-loading-bar/src/slim-loading-bar.service");
var i5 = require("@angular/router");
var i6 = require("./app.component");
var i7 = require("./app.service");
var i8 = require("angular-oauth2-oidc");
var i9 = require("./reference-data/systemsettings/settings.service");
var styles_App = [i0.styles];
var RenderType_App = i1.ɵcrt({ encapsulation: 2, styles: styles_App, data: {} });
exports.RenderType_App = RenderType_App;
function View_App_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng2-slim-loading-bar", [], null, null, null, i2.View_SlimLoadingBarComponent_0, i2.RenderType_SlimLoadingBarComponent)), i1.ɵdid(1, 114688, null, 0, i3.SlimLoadingBarComponent, [i4.SlimLoadingBarService], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [["defaultOverlayTarget", ""]], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, null); }
exports.View_App_0 = View_App_0;
function View_App_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_App_0, RenderType_App)), i1.ɵdid(1, 114688, null, 0, i6.App, [i4.SlimLoadingBarService, i5.Router, i7.AppState, i8.OAuthService, i9.SettingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_App_Host_0 = View_App_Host_0;
var AppNgFactory = i1.ɵccf("app-root", i6.App, View_App_Host_0, {}, {}, []);
exports.AppNgFactory = AppNgFactory;
