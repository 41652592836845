"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var app_resolver_1 = require("./app.resolver");
var app_service_1 = require("./app.service");
var app_config_1 = require("./app.config");
var login_service_1 = require("./login/login.service");
var http_1 = require("@angular/common/http");
var loader_interceptor_1 = require("./interceptors/loader-interceptor");
var settings_service_1 = require("./reference-data/systemsettings/settings.service");
var modal_helper_1 = require("./modal-helper");
// Application wide providers
var APP_PROVIDERS = app_resolver_1.APP_RESOLVER_PROVIDERS.concat([
    login_service_1.LoginService,
    settings_service_1.SettingsService,
    modal_helper_1.ModalHelper,
    app_service_1.AppState,
    app_config_1.AppConfig,
    {
        provide: http_1.HTTP_INTERCEPTORS,
        useClass: loader_interceptor_1.LoaderInterceptor,
        multi: true,
    }
]);
/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
var AppModule = /** @class */ (function () {
    function AppModule(appRef, appState) {
        this.appRef = appRef;
        this.appState = appState;
    }
    return AppModule;
}());
exports.AppModule = AppModule;
