import { NgModule, ApplicationRef, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
/*
 * Platform and Environment providers/directives/pipes
 */
//import { ENV_PROVIDERS } from './environment';
import { ROUTES } from './app.routes';
// App is our top level component
import { App } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InteralStateType } from './app.service';
import { AppConfig } from './app.config';
import { ErrorComponent } from './error/error.component';
import { LoginService } from './login/login.service';

import { BootstrapModalModule } from 'ngx-modialog/plugins/bootstrap';
import { ModalModule } from 'ngx-modialog';

import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './interceptors/loader-interceptor';
import { OAuthModule } from 'angular-oauth2-oidc';
import { SettingsService } from './reference-data/systemsettings/settings.service';
import { ModalHelper } from './modal-helper';

// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    LoginService,
    SettingsService,
    ModalHelper,
    AppState,
    AppConfig,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptor,
        multi: true,
    }
];

type StoreType = {
    state: InteralStateType,
    restoreInputValues: () => void,
    disposeOldHosts: () => void
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
    bootstrap: [App],
    declarations: [
        App,
        ErrorComponent
    ],
    imports: [ // import Angular's modules
        BrowserModule,
        FormsModule,
        HttpModule,
        HttpClientModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        SlimLoadingBarModule.forRoot(),
        BootstrapModalModule,
        OAuthModule.forRoot(),
        RouterModule.forRoot(ROUTES, {
            useHash: true,
            initialNavigation: false
        })
    ],
    exports: [
        SlimLoadingBarModule
    ],
    providers: [ // expose our Services and Providers into Angular's dependency injection
        //ENV_PROVIDERS,
        APP_PROVIDERS,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class AppModule {
    constructor(public appRef: ApplicationRef, public appState: AppState) { }
}

