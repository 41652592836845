"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var service_base_1 = require("app/service-base");
var modal_helper_1 = require("app/modal-helper");
var http_1 = require("@angular/common/http");
var SettingsService = /** @class */ (function (_super) {
    __extends(SettingsService, _super);
    function SettingsService(http, modalHelper) {
        var _this = _super.call(this, modalHelper) || this;
        _this.http = http;
        _this.modalHelper = modalHelper;
        _this._settings = '/api/settings';
        return _this;
    }
    SettingsService.prototype.getOidcConfig = function () {
        return this.http.get(this._settings + '/oidcSettings')
            .map(this.map)
            .catch(this.errorHandler);
    };
    SettingsService.prototype.get = function () {
        return this.http.get(this._settings)
            .map(this.map)
            .catch(this.errorHandler);
    };
    SettingsService.prototype.update = function (appSettings) {
        return this.http.put(this._settings, appSettings)
            .map(this.map)
            .catch(this.errorHandler);
    };
    SettingsService.prototype.map = function (resObject) {
        return resObject.data;
    };
    return SettingsService;
}(service_base_1.ServiceBase));
exports.SettingsService = SettingsService;
