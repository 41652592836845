﻿import { Injectable } from '@angular/core'
import { Modal, bootstrap3Mode } from 'ngx-modialog/plugins/bootstrap';

declare var Messenger: any;

import 'messenger/build/js/messenger.js';
import 'messenger/build/js/messenger-theme-flat.js';
import 'messenger/build/css/messenger.css';
import 'messenger/build/css/messenger-theme-flat.css';

@Injectable()
export class ModalHelper {
    constructor(private modal: Modal) {
        Messenger.options = {
            extraClasses: 'messenger-fixed messenger-on-top',
            theme: 'flat'
        }
        bootstrap3Mode();
    }

    message(title: string, body: string) {
        this.modal
            .alert()
            .size('sm')
            .okBtnClass("btn btn-xs btn-info")
            .title(title).body(body).open();        
    }

    confirm(title: string, body: string, confirm: () => any, cancel: () => any) {
        this.modal
            .confirm().size('sm')
            .okBtnClass("btn btn-xs btn-info").cancelBtnClass("btn btn-xs btn-info")
            .okBtn("Yes").cancelBtn("No")
            .title(title).body(body)
            .open().result.then(res => confirm(), res => cancel());
    }

    customConfirm(title: string, 
                body: string,  
                okBtnText: string = "Yes",
                cancelBtnText: string = "No",
                confirm: () => any, 
                cancel: () => any) {
        this.modal
            .confirm().size('sm')
            .okBtnClass("btn btn-xs btn-info ok").cancelBtnClass("btn btn-xs btn-info cancel")
            .okBtn(okBtnText).cancelBtn(cancelBtnText)
            .title(title).body(body)
            .open().result.then(res => confirm(), res => cancel());
    }

    notifySuccess(message: string) {
        this.notify(message, "success");
    }

    notifyError(message: string) {
        this.notify(message, "danger");
    }

    notifyInfo(message: string) {
        this.notify(message, "info");
    }

    notify(message: string, status: string) {
        Messenger().post({
            message: message,
            type: status,
            hideAfter: 5,
            singleton : true
        });
    }
}