"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bootstrap_1 = require("ngx-modialog/plugins/bootstrap");
require("messenger/build/js/messenger.js");
require("messenger/build/js/messenger-theme-flat.js");
require("messenger/build/css/messenger.css");
require("messenger/build/css/messenger-theme-flat.css");
var ModalHelper = /** @class */ (function () {
    function ModalHelper(modal) {
        this.modal = modal;
        Messenger.options = {
            extraClasses: 'messenger-fixed messenger-on-top',
            theme: 'flat'
        };
        bootstrap_1.bootstrap3Mode();
    }
    ModalHelper.prototype.message = function (title, body) {
        this.modal
            .alert()
            .size('sm')
            .okBtnClass("btn btn-xs btn-info")
            .title(title).body(body).open();
    };
    ModalHelper.prototype.confirm = function (title, body, confirm, cancel) {
        this.modal
            .confirm().size('sm')
            .okBtnClass("btn btn-xs btn-info").cancelBtnClass("btn btn-xs btn-info")
            .okBtn("Yes").cancelBtn("No")
            .title(title).body(body)
            .open().result.then(function (res) { return confirm(); }, function (res) { return cancel(); });
    };
    ModalHelper.prototype.customConfirm = function (title, body, okBtnText, cancelBtnText, confirm, cancel) {
        if (okBtnText === void 0) { okBtnText = "Yes"; }
        if (cancelBtnText === void 0) { cancelBtnText = "No"; }
        this.modal
            .confirm().size('sm')
            .okBtnClass("btn btn-xs btn-info ok").cancelBtnClass("btn btn-xs btn-info cancel")
            .okBtn(okBtnText).cancelBtn(cancelBtnText)
            .title(title).body(body)
            .open().result.then(function (res) { return confirm(); }, function (res) { return cancel(); });
    };
    ModalHelper.prototype.notifySuccess = function (message) {
        this.notify(message, "success");
    };
    ModalHelper.prototype.notifyError = function (message) {
        this.notify(message, "danger");
    };
    ModalHelper.prototype.notifyInfo = function (message) {
        this.notify(message, "info");
    };
    ModalHelper.prototype.notify = function (message, status) {
        Messenger().post({
            message: message,
            type: status,
            hideAfter: 5,
            singleton: true
        });
    };
    return ModalHelper;
}());
exports.ModalHelper = ModalHelper;
