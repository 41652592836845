"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".error-page{background-color:#ddd}.error-container{padding-top:5%;text-align:center}.error-container>.btn{padding-left:35px;padding-right:35px}.error-code{margin:20px;font-size:80px;font-weight:400;color:#373a3c}@media (min-width:768px){.error-code{font-size:180px}}.error-info{font-size:20px;color:#373a3c}.error-help{font-size:14px}.error-page .page-footer{position:absolute;bottom:30px;left:0;right:0;width:100%;font-size:13px;color:#818a91;text-align:center}"];
exports.styles = styles;
