"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var modal_helper_1 = require("./modal-helper");
var rxjs_1 = require("rxjs");
var ServiceBase = /** @class */ (function () {
    function ServiceBase(modalHelper) {
        this.errorHandler = this.errorHandlerUnbinded.bind(this); // for new HttpClient
        this.handleError = this.handleErrorUnbinded.bind(this); // for old Http
        this.modalHelper = modalHelper;
    }
    ServiceBase.prototype.errorHandlerUnbinded = function (httpErrorResponce) {
        switch (httpErrorResponce.status) {
            case 400:
                var text = httpErrorResponce.error || httpErrorResponce.message;
                if (httpErrorResponce.error instanceof Blob) {
                    text = 'Bad request';
                }
                this.modalHelper.notifyError(text || 'Bad request');
                return rxjs_1.throwError(text || 'Bad request');
            case 401:
                this.modalHelper.notifyError("Unauthorized");
                return rxjs_1.throwError(httpErrorResponce.message || 'Unauthorized');
            case 403:
                this.modalHelper.notifyError("Forbidden");
                return rxjs_1.throwError(httpErrorResponce.message || 'Forbidden');
            case 500:
                this.modalHelper.notifyError("Server error. Try again later");
                return rxjs_1.throwError(httpErrorResponce.message || 'Server error');
            case 404:
                this.modalHelper.notifyError("Not found");
                return rxjs_1.throwError(httpErrorResponce.message || 'Not found');
            default:
                console.error(httpErrorResponce);
                return rxjs_1.throwError(httpErrorResponce.message || 'Server error');
        }
    };
    ServiceBase.prototype.handleErrorUnbinded = function (error) {
        switch (error.status) {
            case 400:
                var text = error.text();
                if (error._body instanceof Blob) {
                    text = 'Bad request';
                }
                this.modalHelper.notifyError(text || 'Bad request');
                return rxjs_1.throwError(text || 'Bad request');
            case 401:
                this.modalHelper.notifyError("Unauthorized");
                return rxjs_1.throwError(error.text() || 'Unauthorized');
            case 403:
                this.modalHelper.notifyError("Forbidden");
                return rxjs_1.throwError(error.text() || 'Forbidden');
            case 500:
                this.modalHelper.notifyError("Server error. Try again later");
                return rxjs_1.throwError(error.text() || 'Server error');
            case 404:
                this.modalHelper.notifyError("Not found");
                return rxjs_1.throwError(error.text() || 'Not found');
            default:
                console.error(error);
                return rxjs_1.throwError(error.text() || 'Server error');
        }
    };
    ServiceBase.parseDate = function (value) {
        var current = new Date();
        var date = new Date(value);
        var d = date.getDate();
        var month = date.getMonth();
        var year = date.getFullYear();
        var h = current.getHours();
        var m = current.getMinutes();
        var s = current.getSeconds();
        date.setDate(d);
        date.setMonth(month);
        date.setFullYear(year);
        date.setHours(h);
        date.setMinutes(m);
        date.setSeconds(s);
        return date;
    };
    ServiceBase.parseToUtcDate = function (value) {
        var date = new Date(value);
        var utcDay = date.getUTCDate();
        var utcMonth = date.getUTCMonth();
        var utcYear = date.getUTCFullYear();
        var utcH = date.getUTCHours();
        var utcM = date.getUTCMinutes();
        var utcS = date.getUTCSeconds();
        date.setDate(utcDay);
        date.setMonth(utcMonth);
        date.setFullYear(utcYear);
        date.setHours(utcH);
        date.setMinutes(utcM);
        date.setSeconds(utcS);
        return date;
    };
    ServiceBase.buildDate = function (value) {
        var date = new Date(value);
        var day = date.getDate();
        var month = date.getMonth();
        var year = date.getFullYear();
        var h = date.getHours();
        var m = date.getMinutes();
        var s = date.getSeconds();
        date.setUTCDate(day);
        date.setUTCMonth(month);
        date.setUTCFullYear(year);
        date.setUTCHours(h);
        date.setUTCMinutes(m);
        date.setUTCSeconds(s);
        return date;
    };
    return ServiceBase;
}());
exports.ServiceBase = ServiceBase;
