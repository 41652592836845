"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var app_service_1 = require("./app.service");
var ng2_slim_loading_bar_1 = require("ng2-slim-loading-bar");
var router_1 = require("@angular/router");
var angular_oauth2_oidc_1 = require("angular-oauth2-oidc");
var auth_config_1 = require("./login/auth.config");
var settings_service_1 = require("./reference-data/systemsettings/settings.service");
//import 'messenger/build/js/messenger.js';
//import 'messenger/build/css/messenger.css'
/*
 * App Component
 * Top Level Component
 */
var App = /** @class */ (function () {
    function App(_loadingBar, _router, appState, oauthService, settingsService) {
        var _this = this;
        this._loadingBar = _loadingBar;
        this._router = _router;
        this.appState = appState;
        this.oauthService = oauthService;
        this.settingsService = settingsService;
        this.settingsService.getOidcConfig().subscribe(function (oauthConfig) {
            auth_config_1.authCodeFlowConfig.issuer = oauthConfig.issuer;
            auth_config_1.authCodeFlowConfig.clientId = oauthConfig.appId;
            _this.oauthService.configure(auth_config_1.authCodeFlowConfig);
            _this.oauthService.loadDiscoveryDocumentAndTryLogin().then(function () {
                _this._router.navigate(['/']);
            });
            // subscribe to our router's event here
            _this._router.events.subscribe(function (event) {
                _this.navigationInterceptor(event);
            });
        });
    }
    App.prototype.ngOnInit = function () {
        console.log('Initial App State', this.appState.state);
    };
    /**
     * This is used to intercept and show Loading bar based on the current state of our
     * Router navigation
     * @param {Event} event
     */
    App.prototype.navigationInterceptor = function (event) {
        if (event instanceof router_1.NavigationStart) {
            this._loadingBar.start();
        }
        if (event instanceof router_1.NavigationEnd) {
            this._loadingBar.complete();
        }
        // Set loading state to false in both of the below events to hide the loader in case a request fails
        if (event instanceof router_1.NavigationCancel) {
            this._loadingBar.stop();
        }
        if (event instanceof router_1.NavigationError) {
            this._loadingBar.stop();
        }
    };
    return App;
}());
exports.App = App;
