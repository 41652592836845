import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './dtos/appsettings';
import { ServiceBase } from 'app/service-base';
import { ModalHelper } from 'app/modal-helper';
import { HttpClient } from '@angular/common/http';
import { OauthConfig } from './dtos/oauthconfig';
import { AppSettingsViewModel } from './dtos/appsettings.viewmodel';

@Injectable()
export class SettingsService extends ServiceBase {

    private _settings = '/api/settings';

    constructor(private http: HttpClient, public modalHelper: ModalHelper) {
        super(modalHelper);
    }

    getOidcConfig(): Observable<OauthConfig> {
        return this.http.get<OauthConfig>(this._settings + '/oidcSettings')
            .map(this.map)
            .catch(this.errorHandler);
    }

    get(): Observable<AppSettingsViewModel> {
        return this.http.get<AppSettings>(this._settings)
            .map(this.map)
            .catch(this.errorHandler);
    }

    update(appSettings: AppSettings): Observable<AppSettingsViewModel> {
        return this.http.put(this._settings, appSettings)
            .map(this.map)
            .catch(this.errorHandler);
    }

    map(resObject: any): AppSettings {
        return <AppSettings>resObject.data;
    }
}
