"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Observable_1 = require("rxjs/Observable");
require("rxjs/add/observable/of");
var login_service_1 = require("./login/login.service");
var app_service_1 = require("./app.service");
var DataResolver = /** @class */ (function () {
    function DataResolver() {
    }
    DataResolver.prototype.resolve = function (route, state) {
        return Observable_1.Observable.of({ res: 'I am data' });
    };
    return DataResolver;
}());
exports.DataResolver = DataResolver;
var PermissionsResolver = /** @class */ (function () {
    function PermissionsResolver(_loginService, _appState) {
        this._loginService = _loginService;
        this._appState = _appState;
    }
    PermissionsResolver.prototype.resolve = function (route, state) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._loginService.getPermissions()
                .subscribe(function (permissions) {
                _this._appState.set("permissions", permissions);
                resolve();
            }, reject);
        });
    };
    return PermissionsResolver;
}());
exports.PermissionsResolver = PermissionsResolver;
var UserResolver = /** @class */ (function () {
    function UserResolver(_loginService, _appState) {
        this._loginService = _loginService;
        this._appState = _appState;
    }
    UserResolver.prototype.resolve = function (route, state) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._loginService.getCurrentUserIds()
                .subscribe(function (res) {
                _this._appState.set("currentUserId", res.userId);
                _this._appState.set("currentProfileId", res.profileId);
                resolve();
            }, reject);
        });
    };
    return UserResolver;
}());
exports.UserResolver = UserResolver;
// an array of services to resolve routes with data
exports.APP_RESOLVER_PROVIDERS = [
    DataResolver,
    PermissionsResolver,
    UserResolver
];
