"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
require("rxjs/add/operator/do");
var ng2_slim_loading_bar_1 = require("ng2-slim-loading-bar");
var LoaderInterceptor = /** @class */ (function () {
    function LoaderInterceptor(_loadingBar) {
        this._loadingBar = _loadingBar;
    }
    LoaderInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        // start our loader here
        this._loadingBar.start();
        return next.handle(req).do(function (event) {
            // if the event is for http response
            if (event instanceof http_1.HttpResponse) {
                // stop our loader here
                _this._loadingBar.complete();
            }
        }, function (err) {
            // if any error (not for just HttpResponse) we stop our loader bar
            _this._loadingBar.complete();
        });
    };
    return LoaderInterceptor;
}());
exports.LoaderInterceptor = LoaderInterceptor;
