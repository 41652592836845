/*
 * Angular 2 decorators and services
 */
import { Component, ViewEncapsulation, ViewChild } from '@angular/core';

import { AppState } from './app.service';

import { Overlay, overlayConfigFactory } from 'ngx-modialog';
import { Modal, BSModalContext } from 'ngx-modialog/plugins/bootstrap';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Event } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './login/auth.config';
import { SettingsService } from './reference-data/systemsettings/settings.service';

//import 'messenger/build/js/messenger.js';
//import 'messenger/build/css/messenger.css'
/*
 * App Component
 * Top Level Component
 */
@Component({
    selector: 'app-root',
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        '../../node_modules/messenger/build/css/messenger.css'
    ],
    templateUrl: './app.template.html',
})
export class App {
    ngOnInit() {
        console.log('Initial App State', this.appState.state);
    }

    constructor(
        private _loadingBar: SlimLoadingBarService, 
        private _router: Router,
        private appState: AppState,
        private oauthService: OAuthService,
        private settingsService: SettingsService
    ) {
        this.settingsService.getOidcConfig().subscribe(oauthConfig => {
            authCodeFlowConfig.issuer = oauthConfig.issuer;
            authCodeFlowConfig.clientId = oauthConfig.appId;
            this.oauthService.configure(authCodeFlowConfig);
            this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
                this._router.navigate(['/']);
            });
    
            // subscribe to our router's event here
            this._router.events.subscribe((event: Event) => {
              this.navigationInterceptor(event);
            });
        });
    }
    
    /**
     * This is used to intercept and show Loading bar based on the current state of our
     * Router navigation
     * @param {Event} event
     */
    private navigationInterceptor(event: Event): void {
        if (event instanceof NavigationStart) {
            this._loadingBar.start();
        }
        if (event instanceof NavigationEnd) {
            this._loadingBar.complete();
        }

        // Set loading state to false in both of the below events to hide the loader in case a request fails
        if (event instanceof NavigationCancel) {
            this._loadingBar.stop();
        }
        if (event instanceof NavigationError) {
            this._loadingBar.stop();
        }
    }
}
