﻿import { Observable } from 'rxjs/Observable';
import { Http, Response } from '@angular/http';
import { ModalHelper } from './modal-helper'
import { Injector, Injectable, Inject } from '@angular/core'
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ServiceBase {

    modalHelper: ModalHelper;

    constructor(modalHelper: ModalHelper) {
        this.modalHelper = modalHelper;
    }

    public errorHandler: () => Observable<any> = this.errorHandlerUnbinded.bind(this);      // for new HttpClient
    public errorHandlerUnbinded(httpErrorResponce: HttpErrorResponse) {

        switch (httpErrorResponce.status) {
            case 400:
                let text = httpErrorResponce.error || httpErrorResponce.message;
                if (httpErrorResponce.error instanceof Blob) {
                    text = 'Bad request';
                }
                this.modalHelper.notifyError(text || 'Bad request');
                return throwError(text || 'Bad request');
            case 401:
                this.modalHelper.notifyError("Unauthorized");
                return throwError(httpErrorResponce.message || 'Unauthorized');
            case 403:
                this.modalHelper.notifyError("Forbidden");
                return throwError(httpErrorResponce.message || 'Forbidden');
            case 500:
                this.modalHelper.notifyError("Server error. Try again later");
                return throwError(httpErrorResponce.message || 'Server error');
            case 404:
                this.modalHelper.notifyError("Not found");
                return throwError(httpErrorResponce.message || 'Not found');
            default:
                console.error(httpErrorResponce);
                return throwError(httpErrorResponce.message || 'Server error');
        }
    }


    public handleError: () => Observable<any> = this.handleErrorUnbinded.bind(this);        // for old Http
    public handleErrorUnbinded(error: any) {

        switch (error.status) {
            case 400:
                let text = error.text();
                if (error._body instanceof Blob) {
                    text = 'Bad request';
                }
                this.modalHelper.notifyError(text || 'Bad request');
                return throwError(text || 'Bad request');
            case 401:
                this.modalHelper.notifyError("Unauthorized");
                return throwError(error.text() || 'Unauthorized');
            case 403:
                this.modalHelper.notifyError("Forbidden");
                return throwError(error.text() || 'Forbidden');
            case 500:
                this.modalHelper.notifyError("Server error. Try again later");
                return throwError(error.text() || 'Server error');
            case 404:
                this.modalHelper.notifyError("Not found");
                return throwError(error.text() || 'Not found');
            default:
                console.error(error);
                return throwError(error.text() || 'Server error');
        }
    }

    static parseDate(value: string): Date {
        let current = new Date();

        let date = new Date(value);
        let d = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();
        let h = current.getHours();
        let m = current.getMinutes();
        let s = current.getSeconds();

        date.setDate(d);
        date.setMonth(month);
        date.setFullYear(year);
        date.setHours(h);
        date.setMinutes(m);
        date.setSeconds(s);

        return date;
    }

    static parseToUtcDate(value: string): Date {
        let date = new Date(value);
        let utcDay = date.getUTCDate();
        let utcMonth = date.getUTCMonth();
        let utcYear = date.getUTCFullYear();
        let utcH = date.getUTCHours();
        let utcM = date.getUTCMinutes();
        let utcS = date.getUTCSeconds();

        date.setDate(utcDay);
        date.setMonth(utcMonth);
        date.setFullYear(utcYear);
        date.setHours(utcH);
        date.setMinutes(utcM);
        date.setSeconds(utcS);

        return date;
    }

    static buildDate(value: string): Date {
        let date = new Date(value);

        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();
        let h = date.getHours();
        let m = date.getMinutes();
        let s = date.getSeconds();

        date.setUTCDate(day);
        date.setUTCMonth(month);
        date.setUTCFullYear(year);
        date.setUTCHours(h);
        date.setUTCMinutes(m);
        date.setUTCSeconds(s);

        return date;
    }
}