import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/of';

import { LoginService } from './login/login.service';
import { AppState } from './app.service';

@Injectable()
export class DataResolver implements Resolve<any> {
    constructor() {

    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return Observable.of({ res: 'I am data' });
    }
}

@Injectable()
export class PermissionsResolver implements Resolve<any> {
    constructor(private _loginService: LoginService, private _appState: AppState) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        return new Promise((resolve, reject) => {
            this._loginService.getPermissions()
                .subscribe(permissions => {
                    this._appState.set("permissions", permissions);
                    resolve();
                },
                reject);
        });
    }
}

@Injectable()
export class UserResolver implements Resolve<any>{
    constructor(private _loginService: LoginService, private _appState: AppState) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        return new Promise((resolve, reject) => {
            this._loginService.getCurrentUserIds()
                .subscribe(res => {
                    this._appState.set("currentUserId", res.userId);
                    this._appState.set("currentProfileId", res.profileId);
                    resolve();
                },
                reject);
        });
    }
}


// an array of services to resolve routes with data
export const APP_RESOLVER_PROVIDERS = [
    DataResolver,
    PermissionsResolver,
    UserResolver
];
