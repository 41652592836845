﻿export class Role {
    id: string;
    name: string;
    permissions: Array<RolePermission>;
}

export class RolePermission {
    id: number;
    permissionId: number;
    canCreate: boolean = false;
    canEdit: boolean = false;
    canView: boolean = false;
    canDelete: boolean = false;
    canPrint: boolean = false;
    canGenerate: boolean = false;
    quotationReview: boolean = false;
    quotationApproval: boolean = false;
    overduePayment: boolean = false;
    overdueWindow: boolean = false;
    allProjectInProgress: boolean = false;
    projectNotAllocated: boolean = false;
    userProjectInProgress: boolean = false;

    permissionName: string;
}

export class Permission {
    id: number;
    name: string;
}

export enum Permissions {
    Inquiry = 1,
    Freight = 2,
    Quotation = 3,
    RequestReview = 4,
    ReviewResult = 5,
    RequestApproval = 6,
    ApprovalResult = 7,
    Contract = 8,
    FleetSchedule = 9,
    RequestEngineeringSupport = 10,
    FeedbackEngineeringSupport = 11,
    Client = 12,
    Report = 13,
    SystemConfiguration = 14,
    Users = 15,
    Roles = 16,
    Dashboard = 17,
    ScheduleSimulation = 18,
    InquiryAndFreightList = 19,
    VoyageCostAndIncome = 20,
    VoyageBunkerCalc = 21,
    VoyageInvoice = 22
}